import React, { useEffect, useState } from 'react';
import { ReactComponent as Download } from '../../../assets/download.svg'
import { ReactComponent as DownloadAll } from '../../../assets/downloadAll.svg'
import { ReactComponent as Check } from '../../../assets/check.svg'
import { ReactComponent as PowerOn } from '../../../assets/PowerOnIcon.svg'
import { ReactComponent as PowerOff } from '../../../assets/PowerOffIcon.svg'
import { Oval } from 'react-loader-spinner';

const Row = ({ item, selectedRow, setSelectedRow, setSelectedItem, setShowEmailRequest, pubsub }) => {
    const [selectedDownload, setSelectedDownload] = useState(null);
    const [message, setMessage] = useState()
    const [connected, setConnected] = useState(item.connected)

    useEffect(() => {
        pubsub.subscribe({
            topics: [
                `$aws/events/presence/connected/${item.thingName}`,
                `$aws/events/presence/disconnected/${item.thingName}`
            ]
        }).subscribe({
            next: (data) => {
                console.log(data)
                setMessage(data.msg);
                if (data.eventType === 'connected') {
                    setConnected(true)
                } else if (data.eventType === 'disconnected') {
                    setConnected(false)
                }
            },
            error: (error) => {
                console.log(error)
            }
        })

    }, [])

    const handleRowClick = (item) => {
        if (selectedRow === item.thingName) {
            setSelectedRow(null);
            setSelectedItem(null);
        } else {
            setSelectedRow(item.thingName);
            setSelectedItem(item);
        }
    }

    // Function to handle the single row download click
    const handleDownloadClick = (id) => {
        console.log('download clicked');
        setShowEmailRequest({ show: true, type: 'single', id: id });

    }

    return (
        <div key={item._id} className={item.thingName === selectedRow ? ' table-row table-row-active' : 'table-row'} onClick={() => { handleRowClick(item) }}>
            <div className='table-cell status'>
                {connected ?
                    <PowerOn className='status-icon' />
                    : <PowerOff className='status-icon' />
                }
            </div>
            <div className='table-cell id'>{item.thingName}</div>
            <div className='table-cell'>{item.name}</div>
            <div className='table-cell'>{item.cycleCount > 0 ? item.cycleCount : 0}</div>
            <div className='table-cell'>{item.avgPower > 0 ? item.avgPower.toFixed(2) : 0}</div>
            <div className='table-cell'>{item.avgCycleTime > 0 ? item.avgCycleTime.toFixed(2) : 0}</div>
            <div className='table-cell time'>
                <p>{item.totalCycleTime > 0 ? item.totalCycleTime.toFixed(2) : 0}</p>
                <Download className='download-icon' onClick={() => handleDownloadClick(item._id)} />
            </div>
        </div>
    )
}

export default Row