import React from 'react'
import logo from "../assets/logos/logo.png"
import '../styles/LoadingPage.css'
import { Bars } from 'react-loader-spinner'

const LoadingPage = () => {
    return (
        <div className='loading-container'>
            <div className='loading-box'>
                <img src={logo} alt="se" className="se-logo" />
                <Bars
                    height="80"
                    width="80"
                    color="#ffffff"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>
        </div>
    )
}

export default LoadingPage