import React, { useState } from 'react'
import './styles/EditModal.css'
import { ReactComponent as CloseIcon } from '../assets/close.svg'
import { updateDeviceName } from '../api/DeviceAPI'
import { Oval } from 'react-loader-spinner'


const EditModal = ({ setShowEditModal, selectedItem, setEditSaved, editSaved }) => {
    const [name, setName] = useState(selectedItem.name)
    const [isLoading, setIsLoading] = useState(false)

    const handleNameChange = (e) => {
        setName(e.target.value)
    }

    const handleSave = async () => {
        setIsLoading(true)
        await updateDeviceName(selectedItem._id, name)
        setIsLoading(false)
        setEditSaved(!editSaved)
        setShowEditModal(false)

    }

    return (
        <div className='edit-modal-container'>
            <div className='edit-modal-overlay' onClick={() => { setShowEditModal(false) }}></div>
            <div className='edit-modal'>
                <div className='edit-modal-content'>
                    <div className='edit-modal-header'>
                        <h2>Edit Machine</h2>
                        <CloseIcon className='close-icon' onClick={() => { setShowEditModal(false) }} />
                    </div>
                    <div className='edit-modal-body'>
                        <label htmlFor='machine-name'>Machine Name : </label>
                        <input type='text' id='machine-name' placeholder={name} onChange={(e) => handleNameChange(e)} />
                    </div>
                    <button onClick={() => handleSave()}>{isLoading ?
                        <Oval
                            visible={true}
                            height="27"
                            width="27"
                            secondaryColor="#f6fafe"
                            color='#15629b'
                            ariaLabel="oval-loading"
                            strokeWidth={5}
                        /> : "Save"
                    }</button>
                </div>

            </div>
        </div>

    )
}

export default EditModal