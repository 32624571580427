import React from 'react';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import LoadingPage from './components/LoadingPage';
import ForgotPassword from './components/ForgotPassword';
import Page404 from './components/Page404';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { signOut, getCurrentUser } from 'aws-amplify/auth';
import { Amplify } from 'aws-amplify';
import ResetPassword from './components/ResetPassword';
Amplify.configure({
  Auth: {
    Cognito: {
      identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
      userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID,
    },
  }
});


function App() {


  // Asynchronous sign out function
  async function handleSignOut(navigate) {
    try {
      await signOut();
      navigate("/login");
      console.log("signout success");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Dashboard handleSignOut={handleSignOut} />} />
        <Route
          path="/login"
          element={<Login handleSignOut={handleSignOut} />}
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<Page404 />} />

      </Routes>
    </BrowserRouter>

  );
}

export default App;
