import React, { useState } from 'react';
import '../../styles/dashboard-styles/BodyContainer.css'
import Table from './BodyComponents/Table'
import { ReactComponent as SearchIcon } from '../../assets/search.svg'
import { ReactComponent as NotificationIcon } from '../../assets/notifications.svg'
import { ReactComponent as SettingsIcon } from '../../assets/settingsIcon.svg'

const BodyContainer = ({ tableData, isLoading, date, setDate, editSaved, setEditSaved }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [searchType, setSearchType] = useState('All');
  const [showEditModal, setShowEditModal] = useState(false);

  const handleDateChange = (e) => {
    setDate(e.target.value);
    console.log(date);
  }

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value);
  }

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  }

  const handleEditModal = (selectedRow) => {
    setShowEditModal(true);
  }


  return (
    <div className='body-container'>
      {/* Filter & Search Component */}
      <div className='filter-search-container'>
        <div className='left'>
          <div className='search-container'>
            <SearchIcon className='search-icon' />
            <input type='text' placeholder='Search...' className='search-input' onChange={(e) => { handleSearchChange(e) }} />
          </div>

          <div className='search-type-dropdown' onChange={(e) => handleSearchTypeChange(e)}>
            <select>
              <option value='all'>All</option>
              <option value='id'>ID</option>
              <option value='name'>Machine Name</option>
            </select>
          </div>
        </div>
        <div className='right'>
          <input type='date' value={date} className='date-input' onChange={(e) => handleDateChange(e)} />
          < div className='icons-container' >
            <NotificationIcon className='notification-icon' />
            <SettingsIcon className='settings-icon' style={selectedRow === null ? { color: 'gray' } : { color: '#15629b' }} onClick={() => {
              if (selectedRow !== null) {
                handleEditModal(selectedRow);
              }
            }} />
          </div>
        </div>
      </div>

      {/* Table Component */}
      <Table
        date={date}
        tableData={tableData}
        searchValue={searchValue}
        searchType={searchType}
        isLoading={isLoading}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        editSaved={editSaved}
        setEditSaved={setEditSaved}
      />
    </div>
  );
}

export default BodyContainer