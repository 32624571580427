import react from "react";
import "../styles/Page404.css";
import logo from "../assets/logos/logo.png"
import { useNavigate } from "react-router-dom";

const Page404 = () => {
  const navigate = useNavigate();

  return (
    <div className="not-found-container">
      <div className="logo-container">
        <img src={logo} alt="scientific-logo" className="se-logo" />
      </div>
      <h1 className="error-code">404</h1>
      <h3 className="error-message">Page Not Found</h3>
      <button className="navigate-home" onClick={() => navigate("/")}>
        Go Home
      </button>
    </div>
  );
};

export default Page404;
