import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';

const API_URL = process.env.REACT_APP_API_URL;
const ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone


export const getDeviceData = async (date) => {
    const credentials = await fetchAuthSession();
    const url = `${API_URL}/machines/16a1p?date=${date}&zone=${ZONE}`;
    try {
        const res = await axios.get(url, {
            headers: {
                Authorization: `${credentials.tokens.idToken.toString()}`
            }
        });
        return res.data;
    } catch (error) {
        console.error('Error fetching data: ', error);
        return null;
    }
}

export const updateDeviceName = async (id, name) => {
    const credentials = await fetchAuthSession();
    const url = `${API_URL}/machines/${id}`;
    try {
        const res = await axios.put(url, { name },
            {
                headers: {
                    Authorization: `${credentials.tokens.idToken.toString()}`
                }
            }
        );
        return res.data;
    } catch (error) {
        console.error('Error updating data: ', error);
        return null;
    }
}

export const downloadAllDevicesData = async (zone, date, email) => {
    const credentials = await fetchAuthSession();
    const url = `${API_URL}/machines/16a1p/emailCsv`;
    try {
        const res = await axios.post(url, {

            date,
            zone,
            email

        }, {
            headers: {
                Authorization: `Bearer ${credentials.tokens.idToken.toString()}`
            },
        });
        return res.data;
    } catch (error) {
        console.error('Error fetching data: ', error);
        return null;
    }
}

export const downloadSingleDeviceData = async (id, zone, date, email) => {
    const credentials = await fetchAuthSession();
    const url = `${API_URL}/machines/${id}/emailCsv`;
    try {
        const res = await axios.post(url, {

            date,
            zone,
            email

        }, {
            headers: {
                Authorization: `Bearer ${credentials.tokens.idToken.toString()}`
            },
        });
        return res.data;
    } catch (error) {
        console.error('Error fetching data: ', error);
        return null;
    }
}
