import { React, useState } from 'react'
import './styles/EmailRequest.css'
import { ReactComponent as Close } from '../assets/close.svg'
import { Oval } from 'react-loader-spinner';
import { downloadSingleDeviceData, downloadAllDevicesData } from '../api/DeviceAPI'

const EmailRequest = ({ setShowEmailRequest, csvRequestData, showEmailRequest, setCsvRequestData, date }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [requestEmail, setEmail] = useState('');

    const handleEmailChange = (value) => {
        setEmail(value);
    }

    const handleCSVRequest = async () => {
        setCsvRequestData({ email: requestEmail, date: date, zone: Intl.DateTimeFormat().resolvedOptions().timeZone });
        setIsLoading(true);
        if (showEmailRequest.type === 'single') {
            if (requestEmail === '') {
                alert('Please enter an email');
                setIsLoading(false);
                return;
            } else {
                const res = await downloadSingleDeviceData(showEmailRequest.id, csvRequestData.zone, date, requestEmail);
                if (res) {
                    alert('Data has been sent to your email');
                } else {
                    alert('Data failed to send to your email');
                }
                setIsLoading(false);
                setShowEmailRequest({ show: false, type: 'single', id: null })
            }
        } else if (showEmailRequest.type === 'all') {
            if (requestEmail === '') {
                alert('Please enter an email');
                setIsLoading(false);
                return;
            } else {
                const res = await downloadAllDevicesData(csvRequestData.zone, date, requestEmail);
                if (res) {
                    alert('Data has been sent to your email');
                } else {
                    alert('Data failed to send to your email');
                }
                setIsLoading(false);
                setShowEmailRequest({ show: false, type: 'all', id: null })
            }
        }
    }

    return (
        <div className='email-request-backdrop' >
            <div className='email-request-modal'>
                <div className='modal-top'>
                    <h1>Request Data</h1>
                    <Close className='close-icon' onClick={() => {
                        setShowEmailRequest({ showEmailRequest: false, type: null, id: null });
                    }} />
                </div>
                <div>
                    <div className='input-box email'>
                        <input placeholder='Email Address' type='text' onChange={(e) => handleEmailChange(e.target.value)} />
                    </div>
                </div>
                <button className='login-btn' onClick={() => handleCSVRequest()}>
                    {
                        isLoading ?
                            <Oval
                                color='whitesmoke'
                                secondaryColor='gray'
                                height={15}
                                width={15}
                                strokeWidth={10}
                                timeout={2000}
                            />
                            : 'Request Data'
                    }
                </button>
            </div>
        </div>
    )
}

export default EmailRequest