import { React, useState } from 'react'
import '../styles/ForgotPassword.css'
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logos/logo.png'


const ForgotPassword = () => {
    const [username, setUsername] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleEmailChange = (value) => {
        setUsername(value);
    }

    async function handleResetPassword(username) {
        try {
            setIsLoading(true);
            const output = await resetPassword({
                username, options: {
                    clientMetadata: { url: process.env.REACT_APP_FRONTEND_URL }
                }
            });
            handleResetPasswordNextSteps(output);
        } catch (error) {
            console.log(error);
        }
    }

    function handleResetPasswordNextSteps(output) {
        const { nextStep } = output;
        console.log(nextStep);
        switch (nextStep.resetPasswordStep) {
            case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
                const codeDeliveryDetails = nextStep.codeDeliveryDetails;
                setIsLoading(false);
                alert(`Confirmation code has been sent`);
                break;
            case 'DONE':
                console.log('Successfully reset password.');
                break;
            default:
                console.log('Invalid reset password step.');
        }
    }

    return (
        <div className='login-container'>
            <div className='login-content'>
                <img src={logo} alt='logo' className='logo' />
                <h1 className='title'>Reset Password</h1>
                <div>
                    <div className='input-box password'>
                        <input placeholder='Email Address' type='text' onChange={(e) => handleEmailChange(e.target.value)} />
                    </div>
                </div>
                <button className='login-btn' onClick={() => { handleResetPassword(username) }}>
                    {isLoading ?
                        <Oval
                            visible={true}
                            height="27"
                            width="27"
                            secondaryColor="#f6fafe"
                            color='#15629b'
                            ariaLabel="oval-loading"
                            strokeWidth={5}
                        /> : "Send Reset Link"
                    }
                </button>


            </div>
        </div>
    )
}

export default ForgotPassword