import React, { useState, useEffect } from 'react'
import '../../styles/dashboard-styles/TopContainer.css'
import { ReactComponent as Menu } from '../../assets/menu.svg'
import { ReactComponent as Logout } from '../../assets/logout.svg'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/logos/logo.png'
import logoSmall from '../../assets/logos/logoSmall.png'

const TopContainer = ({ handleSignOut }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // useEffect to update the window width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const navigate = useNavigate();

  return (
    <div className='top-container'>
      <img src={windowWidth > 1280 ? logo : logoSmall} alt='logo' className='se-logo' draggable='false' />
      <h1> Business Intelligence Terminal</h1>
      <div className='menu-icon-container'>
        < Logout className='logout-icon' onClick={() => { handleSignOut(navigate) }} />
      </div>
    </div>
  )
}

export default TopContainer