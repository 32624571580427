import { React, useState } from 'react'
import '../../../styles/dashboard-styles/Table.css'
import { PubSub } from '@aws-amplify/pubsub';
import { ReactComponent as Download } from '../../../assets/download.svg'
import { ReactComponent as DownloadAll } from '../../../assets/downloadAll.svg'
import { ReactComponent as Check } from '../../../assets/check.svg'
import { ReactComponent as Arrows } from '../../../assets/arrows.svg'
import { ReactComponent as ArrowUp } from '../../../assets/arrowUp.svg'
import { ReactComponent as ArrowDown } from '../../../assets/arrowDown.svg'

import { Oval } from 'react-loader-spinner';
import { Bars } from 'react-loader-spinner'
import Row from './Row';
import EditModal from '../../../modals/EditModal'
import EmailRequest from '../../../modals/EmailRequest'

const Table = ({ date, tableData, searchValue, searchType, isLoading, selectedRow, setSelectedRow, setShowEditModal, showEditModal, editSaved, setEditSaved }) => {
    // const [downloadAllStatus, setDownloadAllStatus] = useState('idle');
    const [selectedItem, setSelectedItem] = useState(null);
    const [orderSort, setOrderSort] = useState({ sortBy: 'none', orderBy: 'none' });
    const [csvRequestData, setCsvRequestData] = useState({ email: null, date: date, zone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    const [showEmailRequest, setShowEmailRequest] = useState({ show: false, type: 'single', id: null });

    // Function to handle the all download click
    const handleDownloadAllClick = () => {
        setShowEmailRequest({ show: true, type: 'all', id: null });
    }

    // Apply plugin with configuration
    const pubsub = new PubSub({
        region: process.env.REACT_APP_AWS_IOT_REGION,
        endpoint: process.env.REACT_APP_AWS_IOT_BROKER_ENDPOINT
    });

    // Function to handle the order of the data. Ascending or Descending and should update the state of the data
    const handleSort = (type) => {
        switch (type) {
            case 'thingName':
                orderSort.orderBy === 'none' || orderSort.orderBy === 'desc' ? setOrderSort({ sortBy: 'thingName', orderBy: 'asc' }) : setOrderSort({ sortBy: 'thingName', orderBy: 'desc' });
                break;
            case 'name':
                orderSort.orderBy === 'none' || orderSort.orderBy === 'desc' ? setOrderSort({ sortBy: 'name', orderBy: 'asc' }) : setOrderSort({ sortBy: 'name', orderBy: 'desc' });
                break;
            case 'avgPower':
                orderSort.orderBy === 'none' || orderSort.orderBy === 'desc' ? setOrderSort({ sortBy: 'avgPower', orderBy: 'asc' }) : setOrderSort({ sortBy: 'avgPower', orderBy: 'desc' });
                break;
            case 'cycleCount':
                orderSort.orderBy === 'none' || orderSort.orderBy === 'desc' ? setOrderSort({ sortBy: 'cycleCount', orderBy: 'asc' }) : setOrderSort({ sortBy: 'cycleCount', orderBy: 'desc' });
                break;
            case 'avgCycleTime':
                orderSort.orderBy === 'none' || orderSort.orderBy === 'desc' ? setOrderSort({ sortBy: 'avgCycleTime', orderBy: 'asc' }) : setOrderSort({ sortBy: 'avgCycleTime', orderBy: 'desc' });
                break;
            case 'totalCycleTime':
                orderSort.orderBy === 'none' || orderSort.orderBy === 'desc' ? setOrderSort({ sortBy: 'totalCycleTime', orderBy: 'asc' }) : setOrderSort({ sortBy: 'totalCycleTime', orderBy: 'desc' });
            default:
                break;
        }
    }

    // Filter the data based on the search value and search type
    const filteredData = tableData?.filter((item) => {
        if (searchType === 'All') {
            return item.thingName.toString().includes(searchValue) || item.name.toLowerCase().includes(searchValue.toLowerCase());
        } else if (searchType === 'id') {
            return item.thingName.toString().includes(searchValue);
        } else if (searchType === 'name') {
            return item.name.toLowerCase().includes(searchValue.toLowerCase());
        } else {
            return item
        }
    }).sort((a, b) => {
        let key = orderSort.sortBy;
        if (orderSort.sortBy === 'thingName' || orderSort.sortBy === 'name') {
            return orderSort.orderBy === 'asc' ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key])
        } else if (orderSort.sortBy === 'cycleCount' || orderSort.sortBy === 'avgCycleTime' || orderSort.sortBy === 'avgPower' || orderSort.sortBy === 'totalCycleTime') {
            return orderSort.orderBy === 'asc' ? (a[key] > 0 ? a[key] : 0) - (b[key] > 0 ? b[key] : 0) : (b[key] > 0 ? b[key] : 0) - (a[key] > 0 ? a[key] : 0)
        } else {
            return 0;
        }
    });


    return (
        <div className='table'>
            <div className='table-header'>
                <div className='table-cell status'>
                    <div className='cell-text'>
                        Status
                    </div>
                </div>

                <div className='table-cell id'>
                    <div className='cell-text'>
                        Machine ID
                        <Arrows className='sort-icon' onClick={() => { handleSort("thingName") }} />
                    </div>
                </div>
                <div className='table-cell'>
                    <div className='cell-text'>
                        Machine Name
                        <Arrows className='sort-icon' onClick={() => { handleSort("name") }} />
                    </div>
                </div>

                <div className='table-cell'>
                    <div className='cell-text'>
                        Cycle Count
                        <Arrows className='sort-icon' onClick={() => { handleSort("cycleCount") }} />
                    </div>
                </div>
                <div className='table-cell power'>
                    <div className='cell-text'>
                        Avg Power (Watts)
                        <Arrows className='sort-icon' onClick={() => { handleSort("avgPower") }} />
                    </div>
                </div>
                <div className='table-cell'>
                    <div className='cell-text'>
                        Avg Cycle Time (Sec)
                        <Arrows className='sort-icon' onClick={() => { handleSort("avgCycleTime") }} />
                    </div>
                </div>
                <div className='table-cell time'>
                    <div className='cell-text'>
                        Total Cycle Time (Sec)
                        <Arrows className='sort-icon' onClick={() => { handleSort("totalCycleTime") }} />
                    </div>
                    <DownloadAll className='download-icon' onClick={() => handleDownloadAllClick()} />
                </div>
            </div>
            {showEditModal &&
                <EditModal
                    showEditModal={showEditModal}
                    setShowEditModal={setShowEditModal}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    selectedItem={selectedItem}
                    setEditSaved={setEditSaved}
                    editSaved={editSaved}

                />}
            {showEmailRequest.show &&
                <EmailRequest
                    setShowEmailRequest={setShowEmailRequest}
                    setCsvRequestData={setCsvRequestData}
                    csvRequestData={csvRequestData}
                    showEmailRequest={showEmailRequest}
                    date={date}
                />}
            {
                isLoading ? (
                    <Bars
                        class='loading-icon'
                        height="80"
                        width="80"
                        color="#15629b"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />) :
                    (filteredData?.map((item) => (
                        <Row
                            key={item._id}
                            item={item}
                            selectedRow={selectedRow}
                            setSelectedRow={setSelectedRow}
                            setSelectedItem={setSelectedItem}
                            setShowEmailRequest={setShowEmailRequest}
                            pubsub={pubsub}
                        />)
                    ))
            }
        </div >
    )
}

export default Table