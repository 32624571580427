import React, { useEffect, useState } from 'react'
import '../../styles/dashboard-styles/SidebarContainer.css'
import faustLogo from '../../assets/logos/faustlink-logo.png'
import SidebarItem from './SidebarItem'
import { ReactComponent as RightIcon } from '../../assets/right.svg'
import { ReactComponent as LeftIcon } from '../../assets/left.svg'

const SidebarContainer = ({
  sidebarSelection,
  setSidebarSelection,
}) => {
  const [sidebarMode, setSidebarMode] = useState(false);
  const sidebarItems = [{ title: '16A 1P Machines', icon: '16A' }]

  const handleSidebarMode = () => {
    setSidebarMode(!sidebarMode);
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSidebarSelection = (index) => {
    setSidebarSelection(index)
  }

  if (windowWidth > 1280) {

    return (
      <div className='sidebar-container'>
        {/* {isMobile && <MenuIcon className='menu-icon' onClick={() => { setShowSidebar(true) }} />} */}
        <div className='sidebar-content'>
          {sidebarItems.map((item, index) => {
            return <SidebarItem title={item.title} icon={item.icon} key={index} onClick={() => handleSidebarSelection(index)} />
          })}
        </div>

        <img src={faustLogo} alt='faustLogo' className='faust-logo' />
      </div>
    )
  } else {
    return (
      <div className='mobile-sidebar' id={sidebarMode ? 'slide-out' : 'slide-in'}>
        {sidebarMode && <div className='overlay' onClick={() => handleSidebarMode()}></div>}
        <div className='sidebar-content'>
          <div className='menu-slider' id={sidebarMode ? 'expanded' : 'collapsed'} onClick={() => handleSidebarMode()} >
            {sidebarMode ?
              <LeftIcon className='menu-icon' onClick={() => handleSidebarMode()} />
              : <RightIcon className='menu-icon' onClick={() => handleSidebarMode()} />
            }
          </div>
          {sidebarMode &&
            sidebarItems.map((item, index) => {
              return <SidebarItem title={item.title} icon={item.icon} key={index} onClick={() => handleSidebarSelection(index)} />
            })}
        </div>
        <img src={faustLogo} alt='faustLogo' className='faust-logo' draggable='false' style={sidebarMode ? { display: 'initial' } : { display: 'none' }} />
      </div>
    )
  }
}

export default SidebarContainer 