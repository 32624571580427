import { React, useState, useEffect } from 'react'
import '../styles/Login.css'
import logo from '../assets/logos/logo.png'
import { useNavigate } from 'react-router-dom'
import { signIn, signOut, confirmSignIn, getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { Oval } from 'react-loader-spinner';
import { ReactComponent as VisibilityOn } from '../assets/visibilityOn.svg'
import { ReactComponent as VisibilityOff } from '../assets/visibilityOff.svg'
import AWS from 'aws-sdk'

const Login = () => {
    const [showPassword, setShowPassword] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loginPasswordVisible, setLoginPasswordVisible] = useState(false)
    const [newPasswordVisible, setNewPasswordVisible] = useState(false)
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
    const [permPassword, setPermPassword] = useState(false)
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('')
    const [invalidLogin, setInvalidLogin] = useState(false)
    const [isLoading, setisLoading] = useState(false);
    const navigate = useNavigate()


    // Function to attach the IoT Policy to the Cognito Identity
    function attachPolicy(credentials) {
        var Iot = new AWS.Iot({ region: process.env.REACT_APP_AWS_IOT_REGION, apiVersion: "2015-05-28", endpoint: process.env.REACT_APP_AWS_IOT_SERVER_ENDPOINT, credentials: credentials.credentials });
        var params = { policyName: process.env.REACT_APP_AWS_IOT_POLICY_NAME, target: credentials.identityId };
        // console.log("Attach IoT Policy: " + process.env.REACT_APP_POLICY_NAME + " with cognito identity id: " + credentials.identityId);
        Iot.attachPolicy(params, function (err, data) {
            if (err) {
                console.error(err);
                if (err.code !== 'ResourceAlreadyExistsException') {
                    console.log(err);
                }
            }
            else {
                console.log("Successfully attached policy with the identity", data);
            }
        });
    }

    // Function to check for input autofill
    const checkAutofill = () => {
        const emailInput = document.getElementById('login-email');
        const passwordInput = document.getElementById('login-password');
        if (emailInput && emailInput.value !== email) {
            setEmail(emailInput.value);
        }
        if (passwordInput && passwordInput.value !== password) {
            setPassword(passwordInput.value);
        }
    };

    // Ensure that the user isn't logged in already
    // if the user is logged in it should display the dashboard and if not the login page should be displayed
    async function checkAuthState() {
        try {
            const current = await getCurrentUser();
            navigate('/')
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        // Check for autofill when the email or password changes
        const interval = setInterval(checkAutofill, 200);
        console.log('Run checkAutofill')
        return () => clearInterval(interval);
    }, [email, password]);

    useEffect(() => {
        checkAuthState()
    }, [])

    // Asynchronous sign in function 
    async function handleSignIn({ username, password }, navigate) {
        setisLoading(true)
        try {
            const res = await signIn({ username, password });
            if (res.nextStep?.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
                setPermPassword(true)
                await handleSetPassword();
            } else {
                const credentials = await fetchAuthSession();
                attachPolicy(credentials)
                navigate('/')
                return;
            }
        } catch (error) {
            alert("Invalid Username or Password")
            setInvalidLogin(true)
            console.log('error signing in', error);
            if (error.code === 'UserAlreadyAuthenticatedException') {
                navigate('/')
            }
        }
        setisLoading(false)
    }

    // Check email regex
    const handleEmailCheck = (input) => {
        const emailRegex = /^[^\s@]+@[^\s@]+.[^\s@]+$/;
        return emailRegex.test(input)
    }

    // Handle email change on input 
    const handleEmailChange = (input) => {
        setEmail(input)
    }

    // Handle Password Change on Input
    const handlePasswordChange = (input) => {
        setPassword(input)
    }

    // Check if all fields are filled in
    const handleInputValidation = () => {
        if (email === '' || password === '') {
            return false
        } else {
            return true
        }
    }

    // Handle Login
    const handleLogin = () => {
        const inputValidation = handleInputValidation()
        if (inputValidation === true && handleEmailCheck(email) === true) {
            handleSignIn({ username: email, password: password }, navigate)
        } else {
            alert('Please fill in all fields')
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'keydown' || e.key === 'Enter') {
            handleLogin()
        }
    }

    // Set Permanent Password Logic

    // Handle New Permanent Password Input Change
    const handleNewPasswordChange = (input) => {
        setNewPassword(input)
    }

    // Handle Confirm New Permanent Password Input Change
    const handleConfirmPasswordChange = (input) => {
        setConfirmPassword(input)
    }

    // Check if the new password and confirm password match
    const handlePasswordMatch = () => {
        if (newPassword === confirmPassword && newPassword !== '' && confirmPassword !== '') {
            return true
        } else {
            return false
        }
    }

    // Handle the SetPassword Button Click
    const handleSetClick = async (navigate) => {

        const passwordMatch = handlePasswordMatch()
        if (passwordMatch === true) {
            const res = await handleSetPassword(navigate);
            if (res === true) {
                navigate('/')
            } else {
                alert('Error setting password')
            }
        } else {
            alert('Passwords do not match')
        }

    }

    // Functionality for setting the new Permanent Password
    async function handleSetPassword(navigate) {
        setisLoading(true)
        try {
            await confirmSignIn({
                challengeResponse: newPassword
            });
            setPermPassword(false);
            const credentials = await fetchAuthSession();
            attachPolicy(credentials)
            setisLoading(false)
            return true;
        } catch (error) {
            console.log('error setting password', error);
            return false;
        }

    }

    // Handle Password Visibility

    // Login Password Visibility
    const handleLoginPasswordVisibility = () => {
        setLoginPasswordVisible(!loginPasswordVisible)
    }

    // Set Password Visibility
    const handleNewPasswordVisibility = () => {
        setNewPasswordVisible(!newPasswordVisible)
    }

    const handleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible)
    }

    // Handle Password Visibility Click
    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword)

    }



    // Login component
    return (
        <div className='login-container'>
            <div className='login-content'>
                <img src={logo} alt='logo' className='logo' />
                {permPassword ? <h1 className='title'>Set Password</h1> : <h1 className='title'>Login</h1>}
                {
                    permPassword ? (
                        <>

                            <div>
                                <div className='input-box password'>
                                    <input placeholder='New Password' type={showPassword ? 'text' : 'password'} onChange={(e) => handleNewPasswordChange(e.target.value)} onKeyDown={(e) => handleKeyPress(e)} />
                                    {showPassword ?
                                        <VisibilityOff onClick={() => handlePasswordVisibility()} className='icon' />
                                        : <VisibilityOn onClick={() => handlePasswordVisibility()} className='icon' />
                                    }
                                </div>
                            </div>
                            <div>
                                <div className='input-box password'>
                                    <input placeholder='Confirm Password' type={showPassword ? 'text' : 'password'} onChange={(e) => handleConfirmPasswordChange(e.target.value)} onKeyDown={(e) => handleKeyPress(e)} />
                                    {showPassword ?
                                        <VisibilityOff onClick={() => handlePasswordVisibility()} className='icon' />
                                        : <VisibilityOn onClick={() => handlePasswordVisibility()} className='icon' />
                                    }
                                </div>
                            </div>
                            <button className='login-btn' onClick={() => handleSetClick(navigate)}>
                                {isLoading ?
                                    <Oval
                                        visible={true}
                                        height="27"
                                        width="27"
                                        secondaryColor="#f6fafe"
                                        color='#15629b'
                                        ariaLabel="oval-loading"
                                        strokeWidth={5}
                                    /> : "Login"
                                }
                            </button>
                        </>

                    ) : (
                        <>
                            <div className='input-box'>
                                <input id='login-email' placeholder='Email' type='email' onChange={(e) => handleEmailChange(e.target.value)} onKeyDown={(e) => handleKeyPress(e)} />
                            </div>
                            <div>
                                <div className='input-box password'>
                                    <input id='login-password' placeholder='Password' type={showPassword ? 'text' : 'password'} onChange={(e) => handlePasswordChange(e.target.value)} onKeyDown={(e) => handleKeyPress(e)} />
                                    {showPassword ?
                                        <VisibilityOff onClick={() => handlePasswordVisibility()} className='icon' />
                                        : <VisibilityOn onClick={() => handlePasswordVisibility()} className='icon' />
                                    }
                                </div>
                            </div>
                            <p onClick={() => { navigate('/forgot-password') }} style={{ cursor: 'pointer' }}>Forgot password</p>
                            <button className='login-btn' onClick={() => handleLogin()}>
                                {isLoading ?
                                    <Oval
                                        visible={true}
                                        height="27"
                                        width="27"
                                        secondaryColor="#f6fafe"
                                        color='#15629b'
                                        ariaLabel="oval-loading"
                                        strokeWidth={5}
                                    /> : "Login"
                                }
                            </button>
                        </>
                    )
                }

            </div>
        </div>
    )
}

export default Login