import React from 'react'
import '../../styles/dashboard-styles/SidebarContainer.css'

const SidebarItem = ({ title, icon, index }) => {
    return (
        <div className='sidebar-item '>
            <h4>{title}</h4>
        </div>  
    )
}

export default SidebarItem