import { React, useEffect, useState } from 'react'
import '../styles/Dashboard.css'
import protectedRoute from './ProtectedRoute'
import SidebarContainer from './Dashboard/SidebarContainer'
import TopContainer from './Dashboard/TopContainer'
import BodyContainer from './Dashboard/BodyContainer'
import { getDeviceData } from '../api/DeviceAPI'

const Dashboard = ({ handleSignOut }) => {
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(null)
  const [editSaved, setEditSaved] = useState(false)
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10))
  const [sidebarSelection, setSidebarSelection] = useState(0)

  useEffect(() => {
    getTableData()
  }, [date, editSaved])

  const getTableData = async () => {
    setIsLoading(true)
    const data = await getDeviceData(date)
    setTableData(data)
    setIsLoading(false)
  }

  return (
    <div className='dashboard-container'>
      <TopContainer handleSignOut={handleSignOut} />
      <div className='dashboard-divider'>
        <SidebarContainer sidebarSelection={sidebarSelection} setSidebarSelection={setSidebarSelection} />
        <BodyContainer tableData={tableData} isLoading={isLoading} date={date} setDate={setDate} setEditSaved={setEditSaved} editSaved={editSaved} />
      </div>
    </div>
  )
}

export default protectedRoute(Dashboard)