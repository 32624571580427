import { React, useState, useEffect } from 'react'
import '../styles/Login.css'
import logo from '../assets/logos/logo.png'
import { useNavigate } from 'react-router-dom'
import { Oval } from 'react-loader-spinner';
import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import { ReactComponent as VisibilityOn } from '../assets/visibilityOn.svg'
import { ReactComponent as VisibilityOff } from '../assets/visibilityOff.svg'


const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();


    const handleNewPasswordChange = (value) => {
        setPassword(value);
    }

    const handleConfirmPasswordChange = (value) => {
        setConfirmPassword(value);
    }

    const handleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    }

    const handleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const validatePassword = () => {
        return password === confirmPassword;
    }


    async function handleConfirmResetPassword() {
        try {
            if (!validatePassword()) {
                alert('Passwords do not match');
                return;
            } else {
                setIsLoading(true);
                const url = new URL(window.location.href);
                const searchParams = url.searchParams;
                const confirmationCode = searchParams.get('code')
                const username = searchParams.get('username')
                const res = await confirmResetPassword({ username, confirmationCode, newPassword: password });
                setIsLoading(false);
                navigate('/')

            }
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <div className='login-container'>
            <div className='login-content'>
                <img src={logo} alt='logo' className='logo' />
                <h1 style={{ color: 'white' }}> Reset Password </h1>
                <div>
                    <div className='input-box password'>
                        <input placeholder='New Password' type={showNewPassword ? 'text' : 'password'} onChange={(e) => handleNewPasswordChange(e.target.value)} />
                        {showNewPassword ?
                            <VisibilityOff onClick={() => handleNewPasswordVisibility()} className='icon' />
                            : <VisibilityOn onClick={() => handleNewPasswordVisibility()} className='icon' />
                        }
                    </div>
                </div>
                <div>
                    <div className='input-box password'>
                        <input placeholder='Confirm Password' type={showConfirmPassword ? 'text' : 'password'} onChange={(e) => handleConfirmPasswordChange(e.target.value)} />
                        {showConfirmPassword ?
                            <VisibilityOff onClick={() => handleConfirmPasswordVisibility()} className='icon' />
                            : <VisibilityOn onClick={() => handleConfirmPasswordVisibility()} className='icon' />
                        }
                    </div>
                </div>
                <button className='login-btn' onClick={() => handleConfirmResetPassword()}>
                    {isLoading ?
                        <Oval
                            visible={true}
                            height="27"
                            width="27"
                            secondaryColor="#f6fafe"
                            color='#15629b'
                            ariaLabel="oval-loading"
                            strokeWidth={5}
                        /> : "Reset Password"
                    }
                </button>


            </div>
        </div>
    )
}

export default ResetPassword